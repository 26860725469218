import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionAboutUs.module.css';
import tyreChange from './images/tyreChange.png';
import { string } from 'prop-types';
import { FaArrowRight } from 'react-icons/fa';
import logoImage from '../../assets/logo032024.png';
import border from '../../assets/border.png';
import NamedLink from '../NamedLink/NamedLink';
const SectionAboutUs = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.heroContainer}>
        <div className={css.contentWrapper}>
          <img src={logoImage} className={css.logo} alt="AutoMarketplace" />

          <div className={css.borderContainer}>
            <h1 className={css.sectionTitle}>About Us</h1>
            <img src={border} className={css.border} alt="AutoMarketplace" />
          </div>

          <div className={css.content}>
            <p className={css.paragraph}>
              Before starting AutoMarketplace, we operated a for-hire vehicle (“TLC”) fleet in New
              York City.
            </p>
            <p className={css.paragraph}>
              We know the frustrations of buying, financing, owning, maintaining & fixing vehicles
              in NYC. We also have an expertise in managing administrative work from TLC & DMV
              compliance to{' '}
              <NamedLink name="Service" className={css.link}>
                insurance claims.
              </NamedLink>
            </p>
            <p className={css.paragraph}>
              AutoMarketplace is the site we needed for our fleet. An easy to search local, NYC
              automotive marketplace. You can also easily call or message shops or other vendors. We
              can also{' '}
              <NamedLink name="Service" className={css.link}>
                help
              </NamedLink>{' '}
              you manage an issue, if needed.
            </p>
          </div>
          <br />
          <div className={css.youtube}>[YOUTUBE VIDEO - COMING SOON]</div>
          <br />
          <div className={css.borderContainer}>
            <h1 className={css.sectionTitle}>Trusted Brand In NYC TLC</h1>
            <img src={border} className={css.border} alt="AutoMarketplace" />
          </div>
          <p className={css.paragraph}>
            AutoMarketplace is the largest media company focused on the NYC for- hire transportation
            (“TLC”) market. Our content, including our widely read newsletter, is engaged by NYC
            drivers, fleets, insurance brokers, rideshare companies, bases, taxi medallion owners,
            driver advocates, policymakers and many others.
          </p>
          <div className={css.arrowContainer}>
            <p className={css.newsletter}>
              <a href="https://automarketplace.substack.com/" target="__blank">
                Newsletter <FaArrowRight className={css.arrow} />
              </a>
            </p>
            <p className={css.newsletter}>
              YouTube <FaArrowRight className={css.arrow} />
            </p>
          </div>
          <br />
          <div className={css.borderContainer}>
            <h1 className={css.sectionTitle}>Contact Us</h1>
            <img src={border} className={css.border} alt="AutoMarketplace" />
          </div>
          <p className={css.paragraph}>
            If you have any questions, please email us at info@automarketplace.com or call us at
            (212) 419-4888.
          </p>
        </div>
      </div>
    </div>
  );
};

SectionAboutUs.defaultProps = { rootClassName: null, className: null };

SectionAboutUs.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAboutUs;
