import React, { Component, useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { calculateDistanceInMiles, createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import StarRatings from 'react-star-ratings';
import { types as sdkTypes } from '../../util/sdkLoader';
import { AiFillStar } from 'react-icons/ai';
const { Money } = sdkTypes;
import { GrLocation } from 'react-icons/gr';
import css from './ListingCard.module.css';
import { moneySubUnitAmountAtLeast } from '../../util/validators';
import { formatPhoneNumber } from 'react-phone-number-input';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, partsAmount, partsCurrency, intl) => {
  if (price && price.currency === config.currency) {
    const totalPrice =
      partsAmount && partsCurrency ? new Money(partsAmount + price.amount, partsCurrency) : price;
    const formattedPrice = formatMoney(intl, totalPrice, 0);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    titleClass,
    landingPage = false,
  } = props;
  const classes = classNames(rootClassName || landingPage ? css.rootLanding : css.root, className);
  const [distance, setDistance] = useState(null);
  const [error, setError] = useState(null);
  const currentListing = ensureListing(listing);
  useEffect(() => {
    async function getDistance() {
      try {
        const distance = await calculateDistanceInMiles(geolocation.lat, geolocation.lng);
        setDistance(distance);
      } catch (err) {
        setError(err.code);
        // console.log('err', err);
      }
    }
    getDistance();
  }, []);

  const id = currentListing.id.uuid;
  const { title = '', price, publicData, geolocation } = currentListing.attributes;
  const slug = createSlug(title);
  // const distance = calculateDistanceInMiles(geolocation.lat, geolocation.lng);
  const author = ensureUser(listing.author);
  const authorName =
    author &&
    author.attributes &&
    author.attributes.profile &&
    author.attributes.profile.displayName;
  const authorPublicData =
    (author &&
      author.attributes &&
      author.attributes.profile &&
      author.attributes.profile.publicData) ||
    {};
  const phoneNumber = author?.attributes?.profile?.publicData?.phone_number;
  const callLink = 'tel:' + phoneNumber;
  const { firstName = '', lastName = '' } = authorPublicData;
  const fullName = firstName + ' ' + lastName;
  const totalRating =
    listing &&
    listing.attributes &&
    listing.attributes.metadata &&
    listing.attributes.metadata.rating;
  const rating = totalRating || 0;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const {
    priceUnit,
    category,
    location,
    cityState,
    partsAmount,
    partsCurrency,
    am_rating = 0,
  } = publicData;

  const { formattedPrice, priceTitle } = priceData(price, partsAmount, partsCurrency, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';
  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={landingPage ? css.rootForImageLanding : css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={landingPage ? css.infoLanding : css.info}>
        {/* <div className={css.priceContainer}>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
              {
                config.custom.SUBSCRIPTION_LISTING_TYPES.includes(category) ? 
                <h3>per month</h3> : null
              }
            </div>
            {config.custom.NO_ACTION_LISTING_TYPES.includes(category) ? null : (
              <p className={css.labor}>
                <FormattedMessage id="ListingCard.partsAndLabor" />
              </p>
            )}
          </div>
        </div> */}
        <div className={css.mainInfo}>
          <div className={classNames(titleClass, css.title)}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {cityState ? <span className={css.location}>{cityState}</span> : null}
          <div className={css.authorContainer}>
            {phoneNumber ? (
              <a className={css.text} href={callLink}>
                {formatPhoneNumber(phoneNumber)}
              </a>
            ) : null}
            {/* <div className={css.authorInfo}>{firstName != '' ? fullName : authorName}</div> */}
          </div>
        </div>
        <div className={css.authorRating}>
          <AiFillStar className={css.star} />
          <span className={css.amRatingText}>{am_rating}</span>
          {/* <StarRatings
            rating={1}
            starRatedColor="var(--matterColorGolden)"
            numberOfStars={1}
            starDimension="23px"
            starSpacing="3px"
          />
          <span>{rating}</span> */}
        </div>
      </div>

      {distance && !landingPage ? (
        <div className={css.distance}>
          <span className={css.dist}>
            <GrLocation className={css.locationIcon} />
            Distance. {distance.toFixed(2)} mi
          </span>
        </div>
      ) : null}
      {error && !landingPage ? (
        <div className={css.distance}>
          <span className={css.error}>
            {error == 1 ? 'Enable location to see distance' : 'Something went wrong'}
          </span>
        </div>
      ) : null}
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
