import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, NamedLink } from '../../components';
import StarRatings from 'react-star-ratings';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';

import css from './ListingPage.module.css';
import { formatPhoneNumber } from 'react-phone-number-input';
import Star from '../../components/Star/Star';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    hostLink,
    showContactUser,
    onContactUser,
    isGuideListing,
    priceUnit,
    totalRating,
    params,
    currentListing,
    isSchedulingListing,
    am_rating,
  } = props;
  // console.log({ currentListing });
  const address =
    currentListing &&
    currentListing.attributes &&
    currentListing.attributes.publicData &&
    currentListing.attributes.publicData.location &&
    currentListing.attributes.publicData.location.address
      .replace(', United States', '')
      ?.replace(', USA', '');

  const fullAddress = address && address.split(',');
  const firstAddress = fullAddress && fullAddress?.shift();

  const town = fullAddress && fullAddress?.join(',');
  // const town = fullAddress && fullAddress?.pop();
  // const firstAddress = fullAddress && fullAddress.join(',');
  const phoneNumber = currentListing?.author?.attributes?.profile?.publicData?.phone_number;
  const callLink = 'tel:' + phoneNumber;
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
    ? 'ListingPage.perDay'
    : 'ListingPage.perUnit';
  return (
    <div className={css.sectionHeading}>
      {!isGuideListing ? (
        <div className={css.desktopPriceContainer}>
          <div className={css.desktopPriceValue} title={priceTitle}>
            {formattedPrice}
            {isSchedulingListing ? <h4 className={css.perMonth}>per month</h4> : null}
          </div>
        </div>
      ) : null}
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {/* {category} */}
          {/* <FormattedMessage
            className={css.hostLink}
            id="ListingPage.hostedBy"
            values={{ name: hostLink }}
          /> */}
          {showContactUser ? (
            <span className={css.contactWrapper}>
              {/* <span className={css.separator}>•</span> */}
              <InlineTextButton rootClassName={css.contactLink}>
                <a className={css.text} href={callLink}>
                  <span className={css.call}> Call</span>
                </a>
              </InlineTextButton>
            </span>
          ) : null}
          <span className={css.ratingRating}>
            {/* {showContactUser ? <span className={css.separator}>•</span> : null} */}
            {/* <span className={css.ratedStar}>
              <StarRatings
                rating={1}
                starRatedColor="var(--matterColorGolden)"
                numberOfStars={1}
                starDimension="20px"
                starSpacing="5px"
              />
            </span> */}
            <span>
              <Star />
            </span>
            <span className={css.rating}>
              {
                am_rating
                // totalRating
              }
            </span>
          </span>
        </div>
        <NamedLink
          className={css.address}
          name="ListingPage"
          params={params}
          to={{ hash: '#Location' }}
        >
          {firstAddress}
          {town ? <p className={css.town}>{town}</p> : null}

          {/* {address} */}
        </NamedLink>
        <a className={css.text} href={callLink}>
          {formatPhoneNumber(phoneNumber)}
        </a>

        {/* <p className={css.address}>{address}</p> */}
      </div>
    </div>
  );
};

export default SectionHeading;
