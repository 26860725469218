import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { loadData } from './LandingPage.duck';
import config from '../../config';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
import {
  Page,
  SectionHero,
  SectionYoutubeChannel,
  SectionNewsletter,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  PlainTabs,
  Footer,
  SectionPromotedListings,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/autoMarketPlaceFacebook-1200x630.jpg';
import twitterImage from '../../assets/autoMarketPlaceTwitter-600x314.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import css from './LandingPage.module.css';

import mainCarWashDetailing from './mainCarWashDetailing.png';
import mainBodyShopsTowing from './mainBodyShopsTowing.png';
import tires from './Tires.png';
import rims from './Rims.png';
import towing from './Towing.png';
import acCompressor from './ACcompressor.png';
import alternator from './Alternator.png';
import battery from './Battery.png';
import brakes from './Brakes.png';
import oilChange from './OilChange.png';
import otherAutoRepair from './OtherAutoRepair.png';
import radiator from './Radiator.png';
import stateInspection from './StateInspection.png';
import tireAlignment from './TireRotationAlignment.png';
import tireWheelReapir from './TireWheelRepair.png';
import tlc from './TLCMaintenancePackages.png';
import detailing from './Detailing.png';
import diagnostic from './Diagnostics.png';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    promotedListings,
    viewport,
    queryPromotedListingsError,

    loading,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const promotedBrakes = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'brakes'
  );
  const promotedBattery = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'battery'
  );
  const promotedOilChange = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'oil_change'
  );
  const promotedDiagnostic = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'diagnostics'
  );
  const promotedStateInspection = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'state_inspection'
  );
  const promotedACcompressor = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'ac_compressor'
  );
  const promotedRadiator = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'radiator'
  );
  const promotedAlternator = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'alternator'
  );
  const promotedTLC = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'tlc_maintenance_packages'
  );
  const promotedOtherService = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'other_auto_repair'
  );

  const promotedRims = promotedListings.filter(pl => pl.attributes.publicData.category === 'rims');
  const promotedTireAlignment = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'tire_rotation_alignment'
  );
  const promotedTires = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'tires'
  );
  const promotedTireRimServices = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'tire_rim_services'
  );
  const promotedBodyShops = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'body_shops'
  );
  const promotedTowing = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'towing'
  );

  const promotedStandardAndDeluxeCleaning = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'car_wash_plans'
  );
  const promotedFullDetail = promotedListings.filter(
    pl => pl.attributes.publicData.category === 'detailing'
  );

  const howItWorksRef = useRef(null);
  const scrollToHowItWorks = () =>
    howItWorksRef &&
    howItWorksRef.current &&
    howItWorksRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const [activeTab, setActiveTab] = useState({
    vehicle_maintenance: 'brakes',
    tires: 'tires',
    car_wash_and_detailing: 'car_wash_plans',
    body_shops: 'body_shops',
  });
  const BANNERS = {
    vehicle_maintenance: {
      brakes: {
        img: brakes,
        header: 'LandingPage.brakesHeader',
        subText: 'LandingPage.brakesSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'primary',
      },
      battery: {
        img: battery,
        header: 'LandingPage.batteryHeader',
        subText: 'LandingPage.batterySubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'secondary',
      },
      oil_change: {
        img: oilChange,
        header: 'LandingPage.oilChangeHeader',
        subText: 'LandingPage.oilChangeSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'primary',
      },
      diagnostics: {
        img: diagnostic,
        header: 'LandingPage.diagnosticsHeader',
        subText: 'LandingPage.diagnosticsSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'primary',
      },
      state_inspection: {
        img: stateInspection,
        header: 'LandingPage.stateInspectionHeader',
        subText: 'LandingPage.stateInspectionSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'secondary',
      },
      ac_compressor: {
        img: acCompressor,
        header: 'LandingPage.acCompressorHeader',
        subText: 'LandingPage.acCompressorSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'primary',
      },
      radiator: {
        img: radiator,
        header: 'LandingPage.radiatorHeader',
        subText: 'LandingPage.radiatorSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'secondary',
      },
      alternator: {
        img: alternator,
        header: 'LandingPage.alternatorHeader',
        subText: 'LandingPage.alternatorSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'primary',
      },
      tlc_maintenance_packages: {
        img: tlc,
        header: 'LandingPage.tlcHeader',
        subText: 'LandingPage.tlcSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'secondary',
      },
      other_auto_repair: {
        img: otherAutoRepair,
        header: 'LandingPage.otherAutoRepairHeader',
        subText: 'LandingPage.otherAutoRepairSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'primary',
      },
    },
    tires: {
      tires: {
        img: tires,
        header: 'LandingPage.tiresHeader',
        subText: 'LandingPage.tiresSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'primary',
      },
      rims: {
        img: rims,
        header: 'LandingPage.rimsHeader',
        subText: 'LandingPage.rimsSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'secondary',
      },
      tire_rotation_alignment: {
        img: tireAlignment,
        header: 'LandingPage.tireAlignmentHeader',
        subText: 'LandingPage.tireAlignmentSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'secondary',
      },
      tire_rim_services: {
        img: tireWheelReapir,
        header: 'LandingPage.tireWheelReapirHeader',
        subText: 'LandingPage.tireWheelReapirSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'primary',
      },
    },
    car_wash_and_detailing: {
      car_wash_plans: {
        img: mainCarWashDetailing,
        header: 'LandingPage.mainCarWashDetailingHeader',
        subText: 'LandingPage.mainCarWashDetailingSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'secondary',
      },
      detailing: {
        img: detailing,
        header: 'LandingPage.detailingHeader',
        subText: 'LandingPage.detailingSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'secondary',
      },
    },
    body_shops: {
      body_shops: {
        img: mainBodyShopsTowing,
        header: 'LandingPage.mainBodyShopsTowingHeader',
        subText: 'LandingPage.mainBodyShopsTowingSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'secondary',
      },
      towing: {
        img: towing,
        header: 'LandingPage.towingHeader',
        subText: 'LandingPage.towingSubText',
        buttonText: 'LandingPage.imageButtonText',
        buttonType: 'primary',
      },
    },
  };
  const handleActive = id => {
    const category = Object.entries(BANNERS).filter(category => category[1][id])[0][0];
    const updatedSelectedSubCategory = { [category]: id };
    setActiveTab({
      ...activeTab,
      ...updatedSelectedSubCategory,
    });
  };

  const isPrimaryButtonForMaintenance =
    BANNERS['vehicle_maintenance'][activeTab['vehicle_maintenance']]['buttonType'] === 'primary';
  const isPrimaryButtonForTires = BANNERS['tires'][activeTab['tires']]['buttonType'] === 'primary';
  const isPrimaryButtonForCarWash =
    BANNERS['car_wash_and_detailing'][activeTab['car_wash_and_detailing']]['buttonType'] ===
    'primary';
  const isPrimaryButtonForBodyShop =
    BANNERS['body_shops'][activeTab['body_shops']]['buttonType'] === 'primary';

  const autoRepair = (
    <div>
      {
        <div
          className={css.banners}
          style={{
            backgroundImage:
              'url(' +
              `${BANNERS['vehicle_maintenance'][activeTab['vehicle_maintenance']]['img']}` +
              ')',
          }}
        >
          <div className={css.bannerDataContainer}>
            <h1>
              {' '}
              <FormattedMessage
                id={`${BANNERS['vehicle_maintenance'][activeTab['vehicle_maintenance']]['header']}`}
              />{' '}
            </h1>
            <p>
              {' '}
              <FormattedMessage
                id={`${
                  BANNERS['vehicle_maintenance'][activeTab['vehicle_maintenance']]['subText']
                }`}
              />{' '}
            </p>
            <a
              href={`/s?&pub_category=${activeTab['vehicle_maintenance']}&bounds=40.917577%2C-73.70001442%2C40.477399%2C-74.25909`}
            >
              {isPrimaryButtonForMaintenance ? (
                <PrimaryButton className={css.primaryButton}>
                  <FormattedMessage
                    id={`${
                      BANNERS['vehicle_maintenance'][activeTab['vehicle_maintenance']]['buttonText']
                    }`}
                  />
                  <svg
                    id="Layer"
                    enable-background="new 0 0 64 64"
                    className={css.rightArrow}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
                  </svg>
                </PrimaryButton>
              ) : (
                <SecondaryButton className={css.secondaryButton}>
                  <FormattedMessage
                    id={`${
                      BANNERS['vehicle_maintenance'][activeTab['vehicle_maintenance']]['buttonText']
                    }`}
                  />
                  <svg
                    id="Layer"
                    enable-background="new 0 0 64 64"
                    className={css.rightArrow}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
                  </svg>
                </SecondaryButton>
              )}
            </a>
          </div>
        </div>
      }
    </div>
  );

  const tiresAndRims = (
    <div>
      {
        <div
          className={css.banners}
          style={{
            backgroundImage: 'url(' + `${BANNERS['tires'][activeTab['tires']]['img']}` + ')',
          }}
        >
          <div className={css.bannerDataContainer}>
            <h1>
              {' '}
              <FormattedMessage id={`${BANNERS['tires'][activeTab['tires']]['header']}`} />{' '}
            </h1>
            <p>
              {' '}
              <FormattedMessage id={`${BANNERS['tires'][activeTab['tires']]['subText']}`} />{' '}
            </p>
            <a
              href={`/s?&pub_category=${activeTab['tires']}&bounds=40.917577%2C-73.70001442%2C40.477399%2C-74.25909`}
            >
              {isPrimaryButtonForTires ? (
                <PrimaryButton className={css.primaryButton}>
                  <FormattedMessage id={`${BANNERS['tires'][activeTab['tires']]['buttonText']}`} />
                  <svg
                    id="Layer"
                    enable-background="new 0 0 64 64"
                    className={css.rightArrow}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
                  </svg>
                </PrimaryButton>
              ) : (
                <SecondaryButton className={css.secondaryButton}>
                  <FormattedMessage id={`${BANNERS['tires'][activeTab['tires']]['buttonText']}`} />
                  <svg
                    id="Layer"
                    enable-background="new 0 0 64 64"
                    className={css.rightArrow}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
                  </svg>
                </SecondaryButton>
              )}
            </a>
          </div>
        </div>
      }
    </div>
  );

  const carWashAndDetailing = (
    <div>
      {
        <div
          className={css.banners}
          style={{
            backgroundImage:
              'url(' +
              `${BANNERS['car_wash_and_detailing'][activeTab['car_wash_and_detailing']]['img']}` +
              ')',
          }}
        >
          <div className={css.bannerDataContainer}>
            <h1>
              {' '}
              <FormattedMessage
                id={`${
                  BANNERS['car_wash_and_detailing'][activeTab['car_wash_and_detailing']]['header']
                }`}
              />{' '}
            </h1>
            <p>
              {' '}
              <FormattedMessage
                id={`${
                  BANNERS['car_wash_and_detailing'][activeTab['car_wash_and_detailing']]['subText']
                }`}
              />{' '}
            </p>
            <a
              href={`/s?&pub_category=${activeTab['car_wash_and_detailing']}&bounds=40.917577%2C-73.70001442%2C40.477399%2C-74.25909`}
            >
              {isPrimaryButtonForCarWash ? (
                <PrimaryButton className={css.primaryButton}>
                  <FormattedMessage
                    id={`${
                      BANNERS['car_wash_and_detailing'][activeTab['car_wash_and_detailing']][
                        'buttonText'
                      ]
                    }`}
                  />
                  <svg
                    id="Layer"
                    enable-background="new 0 0 64 64"
                    className={css.rightArrow}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
                  </svg>
                </PrimaryButton>
              ) : (
                <SecondaryButton className={css.secondaryButton}>
                  <FormattedMessage
                    id={`${
                      BANNERS['car_wash_and_detailing'][activeTab['car_wash_and_detailing']][
                        'buttonText'
                      ]
                    }`}
                  />
                  <svg
                    id="Layer"
                    enable-background="new 0 0 64 64"
                    className={css.rightArrow}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
                  </svg>
                </SecondaryButton>
              )}
            </a>
          </div>
        </div>
      }
    </div>
  );

  const bodyShopAndTowing = (
    <div>
      {
        <div
          className={css.banners}
          style={{
            backgroundImage:
              'url(' + `${BANNERS['body_shops'][activeTab['body_shops']]['img']}` + ')',
          }}
        >
          <div className={css.bannerDataContainer}>
            <h1>
              {' '}
              <FormattedMessage
                id={`${BANNERS['body_shops'][activeTab['body_shops']]['header']}`}
              />{' '}
            </h1>
            <p>
              {' '}
              <FormattedMessage
                id={`${BANNERS['body_shops'][activeTab['body_shops']]['subText']}`}
              />{' '}
            </p>
            <a
              href={`/s?&pub_category=${activeTab['body_shops']}&bounds=40.917577%2C-73.70001442%2C40.477399%2C-74.25909`}
            >
              {isPrimaryButtonForBodyShop ? (
                <PrimaryButton className={css.primaryButton}>
                  <FormattedMessage
                    id={`${BANNERS['body_shops'][activeTab['body_shops']]['buttonText']}`}
                  />
                  <svg
                    id="Layer"
                    enable-background="new 0 0 64 64"
                    className={css.rightArrow}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
                  </svg>
                </PrimaryButton>
              ) : (
                <SecondaryButton className={css.secondaryButton}>
                  <FormattedMessage
                    id={`${BANNERS['body_shops'][activeTab['body_shops']]['buttonText']}`}
                  />
                  <svg
                    id="Layer"
                    enable-background="new 0 0 64 64"
                    className={css.rightArrow}
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
                  </svg>
                </SecondaryButton>
              )}
            </a>
          </div>
        </div>
      }
    </div>
  );

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: siteTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer landingPage="true" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              handleOnClick={scrollToHowItWorks}
              viewport={viewport}
              promotedListings={promotedListings}
              error={queryPromotedListingsError}
              loading={loading}
            />
          </div>

          <ul className={css.sections}>
            {/* <li className={css.section}>
              <div className={css.lpContentWrapper}>
                <h2 className={css.categoryHeader}>
                  <span className={css.heading}>
                    <FormattedMessage id="SectionPromotedMaintenance" />
                  </span>
                </h2>
                <PlainTabs
                  currentCategorey={autoRepair}
                  menuListClass={css.promotedSectionTabMenu}
                  data={[
                    {
                      id: 'brakes',
                      label: 'Brakes',
                      handleActive: { handleActive },
                      content:
                        promotedBrakes && promotedBrakes.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedBrakes}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'brakes'}
                          />
                        ) : null,
                    },
                    {
                      id: 'battery',
                      label: 'Batteries',
                      handleActive: { handleActive },
                      content:
                        promotedBattery && promotedBattery.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedBattery}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'battery'}
                          />
                        ) : null,
                    },
                    {
                      id: 'oil_change',
                      label: 'Oil Change',
                      handleActive: { handleActive },
                      content:
                        promotedOilChange && promotedOilChange.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedOilChange}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'oil_change'}
                          />
                        ) : null,
                    },
                    {
                      id: 'diagnostics',
                      label: 'Diagnostics & Check Engine Light',
                      handleActive: { handleActive },
                      content:
                        promotedDiagnostic && promotedDiagnostic.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedDiagnostic}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'diagnostics'}
                          />
                        ) : null,
                    },
                    {
                      id: 'state_inspection',
                      label: 'State Inspection',
                      handleActive: { handleActive },
                      content:
                        promotedStateInspection && promotedStateInspection.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedStateInspection}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'state_inspection'}
                          />
                        ) : null,
                    },
                    {
                      id: 'ac_compressor',
                      label: 'AC Compressor',
                      handleActive: { handleActive },
                      content:
                        promotedACcompressor && promotedACcompressor.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedACcompressor}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'ac_compressor'}
                          />
                        ) : null,
                    },
                    {
                      id: 'radiator',
                      label: 'Radiator',
                      handleActive: { handleActive },
                      content:
                        promotedRadiator && promotedRadiator.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedRadiator}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'radiator'}
                          />
                        ) : null,
                    },
                    {
                      id: 'alternator',
                      label: 'Alternator',
                      handleActive: { handleActive },
                      content:
                        promotedAlternator && promotedAlternator.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedAlternator}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'alternator'}
                          />
                        ) : null,
                    },
                    {
                      id: 'tlc_maintenance_packages',
                      label: 'TLC Maintenance Packages',
                      handleActive: { handleActive },
                      content:
                        promotedTLC && promotedTLC.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedTLC}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'tlc_maintenance_packages'}
                          />
                        ) : null,
                    },
                    {
                      id: 'other_auto_repair',
                      label: 'Other Auto Repair',
                      handleActive: { handleActive },
                      content:
                        promotedOtherService && promotedOtherService.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedOtherService}
                            showAll={'SectionPromotedService.showAll'}
                            allRelatedQuery={'other_auto_repair'}
                          />
                        ) : null,
                    },
                  ]}
                />
              </div>
            </li> */}
            {/* <li className={css.section}>
              <div className={css.lpContentWrapper}>
                <h2 className={css.categoryHeader}>
                  <span className={css.heading}>
                    {' '}
                    <FormattedMessage id="SectionPromotedWheels" />
                  </span>
                </h2>
                <PlainTabs
                  currentCategorey={tiresAndRims}
                  menuListClass={css.promotedSectionTabMenu}
                  data={[
                    {
                      id: 'tires',
                      label: 'Tires',
                      handleActive: { handleActive },
                      content:
                        promotedTires && promotedTires.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedTires}
                            showAll={'SectionPromotedTires.showAll'}
                            allRelatedQuery={'tires'}
                          />
                        ) : null,
                    },
                    {
                      id: 'rims',
                      label: 'Rims',
                      handleActive: { handleActive },
                      content:
                        promotedRims && promotedRims.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedRims}
                            showAll={'SectionPromotedRims.showAll'}
                            allRelatedQuery={'rims'}
                          />
                        ) : null,
                    },
                    {
                      id: 'tire_rotation_alignment',
                      label: 'Tire Rotation & Alignment',
                      handleActive: { handleActive },
                      content:
                        promotedTireAlignment && promotedTireAlignment.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedTireAlignment}
                            showAll={'SectionPromotedTireRimServices.showAll'}
                            allRelatedQuery={'tire_rotation_alignment'}
                          />
                        ) : null,
                    },
                    {
                      id: 'tire_rim_services',
                      label: 'Tire & Wheel Repair',
                      handleActive: { handleActive },
                      content:
                        promotedTireRimServices && promotedTireRimServices.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedTireRimServices}
                            showAll={'SectionPromotedTireRimServices.showAll'}
                            allRelatedQuery={'tire_rim_services'}
                          />
                        ) : null,
                    },
                  ]}
                />
              </div>
            </li> */}
            {/* <li className={css.section}>
              <div className={css.lpContentWrapper}>
                <h2 className={css.categoryHeader}>
                  <span className={css.heading}>
                    <FormattedMessage id="SectionPromotedDetailing" />
                  </span>
                </h2>
                <PlainTabs
                  currentCategorey={carWashAndDetailing}
                  menuListClass={css.promotedSectionTabMenu}
                  data={[
                    {
                      id: 'car_wash_plans',
                      label: 'Car Wash Plans',
                      handleActive: { handleActive },
                      content:
                        promotedStandardAndDeluxeCleaning &&
                        promotedStandardAndDeluxeCleaning.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedStandardAndDeluxeCleaning}
                            showAll={'SectionPromotedStandardAndDeluxeCleaning.showAll'}
                            allRelatedQuery={'car_wash_plans'}
                          />
                        ) : null,
                    },
                    {
                      id: 'detailing',
                      label: 'Detailing',
                      handleActive: { handleActive },
                      content:
                        promotedFullDetail && promotedFullDetail.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedFullDetail}
                            showAll={'SectionPromotedFullDetail.showAll'}
                            allRelatedQuery={'detailing'}
                          />
                        ) : null,
                    },
                  ]}
                />
              </div>
            </li> */}

            {/* <li className={css.section}>
              <div className={css.lpContentWrapper}>
                <h2 className={css.categoryHeader}>
                  <span className={css.heading}>
                    <FormattedMessage id="SectionPromotedTowing" />
                  </span>
                </h2>
                <PlainTabs
                  currentCategorey={bodyShopAndTowing}
                  menuListClass={css.promotedSectionTabMenu}
                  data={[
                    {
                      id: 'body_shops',
                      label: 'Body Shops',
                      handleActive: { handleActive },
                      content:
                        promotedBodyShops && promotedBodyShops.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedBodyShops}
                            showAll={'SectionpromotedBodyShops.showAll'}
                            allRelatedQuery={'body_shops'}
                          />
                        ) : null,
                    },
                    {
                      id: 'towing',
                      label: 'Towing',
                      handleActive: { handleActive },
                      content:
                        promotedTowing && promotedTowing.length > 0 ? (
                          <SectionPromotedListings
                            intl={intl}
                            promotedListings={promotedTowing}
                            showAll={'SectionPromotedTowing.showAll'}
                            allRelatedQuery={'towing'}
                          />
                        ) : null,
                    },
                  ]}
                />
              </div>
            </li> */}
          </ul>
          {/* <div className={`${css.youtubeChannel} ${css.lpContentWrapper}`}>
            <SectionYoutubeChannel history={history} location={location} />
          </div> */}
          {/* <div className={`${css.newsletter} ${css.lpContentWrapper}`} ref={howItWorksRef}>
            <SectionNewsletter history={history} location={location} />
          </div> */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, shape, number } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    promotedListingRefs,
    queryPromotedListingsError,

    loading,
  } = state.LandingPage;

  const promotedListings = getMarketplaceEntities(state, promotedListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    promotedListings,
    queryPromotedListingsError,

    loading,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = loadData;

export default LandingPage;
