import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import bnplImg from './bnpl.png';
import completedImg from './completed.png';
import logoImage from '../../assets/logo032024.png';
import imageWeb3 from '../../assets/web3Image.png';
import coins from '../../assets/coins.png';
import css from './BNPL.module.css';
import border from '../../assets/border.png';
const BNPLPage = () => {
  // prettier-ignore
  const mailLink = 'mailto:' + "info@automarketplace.com";
  return (
    <StaticPage
      title="web3"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Web3',
        description: 'Web3',
        name: 'BNPLPage',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.sectionOne}>
            <img src={logoImage} className={css.logo} alt="AutoMarketplace" />
            <div className={css.borderContainer}>
              <h1 className={css.sectionTitle}>Web3</h1>
              <img src={border} className={css.border} alt="AutoMarketplace" />
            </div>
            <p className={css.paragraph}>
              Web3 is an idea that the next iteration of internet commerce will be defined by
              “decentralization” . To be simple, it basically means the economics of the internet
              will be more evenly distributed (less “middlemen”). One way this is facilitated is by
              using blockchain technologies.
            </p>
            <h2 className={css.subheading}>Web3 Vision (some simple examples)</h2>
            <ul className={css.list}>
              <li className={css.paragraphli}>
                Comparing NYC commercial for-hire vehicle insurance costs?
              </li>
              <li className={css.paragraphli}>Lowering your TLC insurance premium?</li>
              <li className={css.paragraphli}>
                Getting “full coverage” insurance for your vehicle?
              </li>
              <li className={css.paragraphli}>Managing an insurance claim or process?</li>
            </ul>
            <p className={css.paragraph}>Let’s use an easy to understand illustrative example.</p>
          </div>
          <div className={css.sectionTwo}>
            <img src={imageWeb3} className={css.imageWeb3} alt="Web3" />
          </div>
          <div className={css.sectionThree}>
            <div className={css.borderContainer}>
              <h1 className={css.sectionTitle}>Blockchain Reward Tokens</h1>
              <img src={border} className={css.borderBlock} alt="AutoMarketplace" />
            </div>
            <p className={css.paragraph}>
              <b>AutoMarketplace Reward Tokens</b> will provide discounts and other benefits to
              those who use our marketplace. When you register on our site, you’ll automatically be
              put on our waitlist.
            </p>
            <div className={css.imageContainer}>
              <img src={logoImage} className={css.completed} alt="Completed" />
              <img src={coins} className={css.coins} alt="Coins" />
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default BNPLPage;
